import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Flex, Box } from 'rebass'
import { clientSyncRefresh } from '@doric-blockchain/doric-core/actions/main'
import {
  set_transaction,
  reset_transaction,
} from '@doric-blockchain/doric-core/actions/wallet'
import { EthersHelpers } from '@doric-blockchain/doric-core/helpers'
import { sendSignedTransaction } from '@doric-blockchain/doric-core/actions/transaction'
import { useTranslation } from 'react-i18next'
import { useApplicationState } from 'store/application/state'
import { CONFIRM_TRANSACTION } from 'store/application/types'
import { useNetworkState } from 'store/network/state'
import { ButtonPrimary } from 'components/Button'
import { FormInputRow, SimpleInput } from 'components/Forms/inputs'
import ConfirmSend from 'components/ConfirmSend'
import { CurrencyListInput } from 'components/CurrencyInputPanel'
import { MaxButton, TransactionForm } from './styles'
import { getDialogByResponse } from './utils'

const SendTransaction = ({
  transaction,
  set_transaction,
  reset_transaction,
  clientSyncRefresh,
  client,
  sendSignedTransaction,
}) => {
  const { t } = useTranslation()
  const { selectedNetwork } = useNetworkState()

  const { tokens, tokensLoading, balance, address } = client

  const { openPopup, closePopup } = useApplicationState()
  const [touched, setTouched] = useState({})

  const nativeCoin = {
    name: process.env.REACT_APP_COIN_NAME,
    symbol: process.env.REACT_APP_COIN_SYMBOL,
    address: process.env.REACT_APP_COIN_SYMBOL,
    balance,
  }
  const [currency, setCurrency] = useState(nativeCoin)

  const displayTokens = [nativeCoin, ...tokens]

  useEffect(() => {
    if (currency.symbol === process.env.REACT_APP_COIN_SYMBOL)
      setCurrency(nativeCoin)
    // eslint-disable-next-line
  }, [balance])

  function closeTransactionForm() {
    closePopup(CONFIRM_TRANSACTION)
  }

  function resetTransaction() {
    reset_transaction()
    setTouched({})
  }

  function handleReponse(message, onTryAgain, params) {
    const { display } = getDialogByResponse(message, selectedNetwork, {
      onClose: () => closePopup('TRANSACTION_EVENTS'),
      onTryAgain: () => {
        closePopup('TRANSACTION_EVENTS')
        onTryAgain()
      },
      params,
    })

    if (display)
      openPopup('TRANSACTION_EVENTS', () => {
        return display
      })

    clientSyncRefresh({ address }, selectedNetwork)
    closeTransactionForm()
  }

  function openTransactionConfirm() {
    openPopup(CONFIRM_TRANSACTION, () => (
      <ConfirmSend
        currency={currency}
        onConfirm={form => {
          try {
            sendSignedTransaction(
              {
                password: form.password,
                transaction: {
                  ...transaction,
                  contractAddress: currency.address,
                },
                onSuccess: (message, params) => {
                  console.log({ message, params })
                  handleReponse(message, openTransactionConfirm, params)
                  resetTransaction()
                },
                onFail: (message, params) => {
                  console.log({ message, params })
                  handleReponse(message, openTransactionConfirm, params)
                },
                onEvent: message => {
                  console.log({ message })
                  handleReponse(message, openTransactionConfirm)
                },
              },
              selectedNetwork,
            )
          } catch (error) {
            console.error(error)
            handleReponse('ERROR', openTransactionConfirm, { error })
          }
        }}
        onCancel={closeTransactionForm}
      />
    ))
  }

  function doChange(key, value) {
    if (!touched[key]) setTouched({ ...touched, [key]: true })
    set_transaction({ key, value })
  }

  const invalidGasPrice = !EthersHelpers.is_valid_gas_price(transaction.gas)
  const invalidAddress = !EthersHelpers.is_address_valid(transaction.to)
  const noBalanceEnough = !EthersHelpers.enough_balance(
    transaction.amount,
    currency.balance,
  )

  const blockTransaction = invalidGasPrice || invalidAddress || noBalanceEnough

  function handlerSelectedCurrency(currency) {
    setCurrency(currency)
  }

  return (
    <TransactionForm>
      <CurrencyListInput
        currency={currency}
        tokensList={displayTokens}
        tokensLoading={tokensLoading}
        onSelectCurrency={handlerSelectedCurrency}
        value={transaction.amount}
        onUserInput={newValue => doChange('amount', newValue)}
        error={touched['amount'] && noBalanceEnough}
        success={touched['amount'] && !noBalanceEnough}
        tabIndex="1"
      />

      <Flex
        justifyContent="space-between"
        alignItems="center"
        marginTop="-15px"
      >
        <Box>
          <div
            style={{
              fontSize: '11px',
              fontWeight: 'bold',
            }}
          >
            {currency.balance} {currency.symbol}
          </div>
        </Box>

        <Box>
          <MaxButton onClick={() => doChange('amount', currency.balance)}>
            MAX
          </MaxButton>
        </Box>
      </Flex>

      <div
        style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
      ></div>

      <FormInputRow
        error={touched['to'] && invalidAddress}
        success={!invalidAddress}
      >
        <div style={{ width: '120px', padding: 0 }}>Address</div>
        <SimpleInput
          value={transaction.to}
          onChange={e => doChange('to', e.target.value)}
          fontSize="11px"
          tabIndex="2"
          style={{ textAlign: 'right' }}
        />
      </FormInputRow>

      <div style={{ width: '160px', marginBottom: '15px' }}>
        <FormInputRow error={invalidGasPrice}>
          <div style={{ width: '130px', padding: 0 }}>GAS Fee</div>
          <SimpleInput
            style={{ textAlign: 'right' }}
            value={transaction.gas}
            onChange={e => doChange('gas', e.target.value)}
            tabIndex="3"
          />
        </FormInputRow>
      </div>

      <ButtonPrimary
        onClick={openTransactionConfirm}
        width="auto"
        padding="10px"
        disabled={blockTransaction}
        tabIndex="4"
      >
        {t('SEND')} {currency.symbol}
      </ButtonPrimary>
    </TransactionForm>
  )
}

SendTransaction.propTypes = {
  transaction: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
}

const state = ({ transaction, client }) => ({ transaction, client })
export default connect(state, {
  set_transaction,
  reset_transaction,
  clientSyncRefresh,
  sendSignedTransaction,
})(SendTransaction)
