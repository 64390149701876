import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { generate_seed } from '@doric-blockchain/doric-core/actions/wallet'
import { SeedBox } from './styles'
import SlideContainer from 'pages/Slides/SlideContainer'
import { ButtonGray, ButtonLight } from 'components/Button'
import { TYPE } from 'theme'
import ExclamationIcon from 'components/SVG/ExclamationIcon'
import { ColumnCenter } from 'components/Column'
import SimpleLoader from 'components/SimpleLoader'

const ButtonCard = styled(ColumnCenter)`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  width: 60%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};
`

const Seed = ({ generate_seed, seed, history }) => {
  const { t } = useTranslation()
  const { string: WALLET_SEED } = seed

  useEffect(() => {
    generate_seed()
  }, [generate_seed])

  const displaySeed = !Array.isArray(WALLET_SEED)
    ? WALLET_SEED.split(` `)
    : WALLET_SEED

  return (
    <SlideContainer>
      <center>
        <TYPE.largeHeader style={{ maxWidth: 314, fontSize: '20px' }}>
          {t(`Copy your seed phrase and save it in a safe place.`)}
        </TYPE.largeHeader>
        {t(`That is the only way to recover your wallet!`)}
      </center>
      <SeedBox data-cy="seed-box">
        <p>
          {displaySeed.length === 0 && (
            <center>
              <SimpleLoader />
            </center>
          )}
          {displaySeed?.map((s, index) => (
            <span key={s}>{`${s}${index < 11 ? ' ' : ''}`}</span>
          ))}
        </p>
      </SeedBox>
      <span style={{ color: `#E60B0B`, fontWeight: 'bold' }}>
        <ExclamationIcon />
        {` ${t(`Remember to write down your seed`)}`}
      </span>

      <span style={{ color: `#E60B0B`, marginBottom: 27 }}>
        {t(`You will need that if you lose your wallet`)}
      </span>

      <ButtonCard>
        <ButtonGray
          onClick={() => history.go(-1)}
          style={{ cursor: `pointer` }}
        >
          {t(`Go back`)}
        </ButtonGray>

        <ButtonLight
          margin="5px"
          width="100%"
          onClick={() => history.push(`/seed/inform`)}
          data-cy="btn-inform-seed"
        >
          {t(`Continue`)}
        </ButtonLight>
      </ButtonCard>
    </SlideContainer>
  )
}

const state = ({ seed }) => ({ seed })
export default connect(state, { generate_seed })(withRouter(Seed))
