import React, { Suspense } from 'react'
import { HashRouter as Router, Switch } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { init } from '@doric-blockchain/doric-core/actions/main'
import Header from 'components/Header'
import ModalProvider from 'components/ModalProvider'
import { AutoColumn } from 'components/Column'
import InitController from 'InitController'
import { routes } from './routes'
import ProtectedRoute from 'components/ProtectedRoute'
import CustomErrorBoundary from 'components/CustomErrorBoundary'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  height: 100%;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5rem 1rem;
  align-items: center;
  justify-content: center;
  flex: 1;
  z-index: 1;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 5rem 1rem;
  `};
`

const PageWrapper = styled(AutoColumn)`
  max-width: 870px;
  width: 100%;
  justify-items: center;
  padding: ${({ padding }) => padding};
  margin: 0 0 85px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 800px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 500px;
  `};
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 2;
`

function App() {
  return (
    <Suspense fallback={null}>
      <Router>
        <AppWrapper>
          <ModalProvider />

          <HeaderWrapper>
            <Header />
          </HeaderWrapper>

          <ErrorBoundary FallbackComponent={CustomErrorBoundary}>
            <BodyWrapper gap="lg" justify="center">
              <InitController />
              <PageWrapper>
                <Switch>
                  {routes.map(({ path, component, authRequired, exact }) => {
                    return (
                      <ProtectedRoute
                        key={path}
                        path={path}
                        component={component}
                        authRequired={authRequired}
                        exact={exact}
                      />
                    )
                  })}
                </Switch>
              </PageWrapper>
            </BodyWrapper>
          </ErrorBoundary>
        </AppWrapper>
      </Router>
    </Suspense>
  )
}

export default connect(null, { init })(App)
