import React from 'react'

export default function ExclamationIcon() {
  return (
    <svg
      width="15px"
      height="15px"
      viewBox="0 0 15 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="seed"
          transform="translate(-375.000000, -357.000000)"
          fill="#E60B0B"
        >
          <path
            d="M382.5,357 C378.36,357 375,360.36 375,364.5 C375,368.64 378.36,372 382.5,372 C386.64,372 390,368.64 390,364.5 C390,360.36 386.64,357 382.5,357 L382.5,357 Z M383.25,368.25 L381.75,368.25 L381.75,366.75 L383.25,366.75 L383.25,368.25 L383.25,368.25 Z M383.25,365.25 L381.75,365.25 L381.75,360.75 L383.25,360.75 L383.25,365.25 L383.25,365.25 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  )
}
