import {
  Backup,
  CreateSeed,
  Dashboard,
  InformSeed,
  Login,
  NewWallet,
  Seed,
  SendReceiver,
  Signup,
  Slide1,
  Slide2,
  Slide3,
  Slide4,
  Welcome,
  History,
  Tokens,
  Stake,
} from 'pages'

export const routes = [
  { path: '/login', component: Login },
  { path: '/welcome', component: Welcome },
  { path: '/slide/1', component: Slide1 },
  { path: '/slide/2', component: Slide2 },
  { path: '/slide/3', component: Slide3 },
  { path: '/slide/4', component: Slide4 },
  { path: '/seed', component: Seed, exact: true },
  { path: '/seed/inform', component: InformSeed },
  { path: '/seed/create', component: CreateSeed },
  { path: '/signup', component: Signup },
  { path: '/seed/wallet/new', component: NewWallet },
  { path: '/', component: Dashboard, authRequired: true, exact: true },
  { path: '/welcome', component: Welcome, authRequired: true },
  { path: '/history', component: History, authRequired: true },
  { path: '/backup', component: Backup, authRequired: true },
  { path: '/send-receive', component: SendReceiver, authRequired: true },
  { path: '/tokens', component: Tokens, authRequired: true },
  { path: '/stake', component: Stake, authRequired: true },
]
