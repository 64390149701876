import { ethers } from 'ethers'
import { getStakeContractAddress } from '@doric-blockchain/doric-core/constants'

export function getContractCallInfo(transaction) {
  const { fromLinks = [], toLinks = [] } = transaction || {}

  let isContractCall = false
  let contractAddress

  const joined = [...fromLinks, ...toLinks]

  if (joined.length > 0) {
    const filtered = joined.find(({ rel }) => rel === 'contract')
    contractAddress = filtered?.display
    isContractCall = filtered?.rel === 'contract'
  }

  return {
    isContractCall,
    contractAddress,
  }
}

export function formatBigNumber(value) {
  if (value) return ethers.formatUnits(value, 'ether')
}

export function getTxParams(transaction) {
  if (transaction?.functionMeta?.params?.length > 0)
    return transaction.functionMeta.params
  if (transaction?.parameters?.length > 0) return transaction.parameters
  return []
}

export function displayAmount(parameters, defaultValue = '') {
  if (parameters) {
    const _valueParam = parameters.find(
      ({ name }) => name === '_value' || name === 'value',
    )
    if (_valueParam?.value) return formatBigNumber(_valueParam.value)
  }

  return defaultValue
}

export function sameAddress(address1, address2) {
  if (!address1 || !address2) return false
  return address1.toLowerCase() === address2.toLowerCase()
}

export function displayToAddress(transaction) {
  if (transaction.address === transaction.to) return transaction.from

  return transaction?.to
}

export function displayAction(transaction) {
  const action = transaction.contractAddress ? 'Contract Call' : 'Transfer'

  let append =
    transaction.address === transaction.to ? ': deposit' : ': withdrawn'

  if (transaction.contractAddress === getStakeContractAddress())
    append = ': Staking contract'
  return `${action}${append}`
}
