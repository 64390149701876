import axios from 'axios'

const __selectBaseUrl = selectedNetwork => {
  if (selectedNetwork === process.env.REACT_APP_NETWORK) {
    return process.env.REACT_APP_EXPLORER_HTTP
  }

  return process.env.REACT_APP_EXPLORER_HTTP_TESTNET
}

export function getExplorerURL(selectedNetwork) {
  return `${__selectBaseUrl(selectedNetwork)}`
}

export function getExplorerTXURL(hash, selectedNetwork) {
  return `${__selectBaseUrl(selectedNetwork)}/tx/${hash}`
}

export function getTransactionsByAddressURL(address, selectedNetwork) {
  const url = `${__selectBaseUrl(
    selectedNetwork,
  )}/api?module=account&action=txlist&address=${address}`
  return url
}

export function getTokensByAddressURL(address, selectedNetwork) {
  const url = `${__selectBaseUrl(
    selectedNetwork,
  )}/api?module=account&action=tokentx&address=${address}`
  return url
}

export async function getTransactionsByAddress(address, selectedNetwork) {
  const { data } = await axios.get(
    getTransactionsByAddressURL(address, selectedNetwork),
  )
  return data
}

export async function getTokensByAddress(address, selectedNetwork) {
  const { data } = await axios.get(
    getTokensByAddressURL(address, selectedNetwork),
  )
  return data
}
