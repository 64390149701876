import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import fileDownload from 'js-file-download'
import styled from 'styled-components'
import { connect } from 'react-redux'
import copy from 'copy-to-clipboard'
import {
  get_seed_and_pk,
  get_key_store,
} from '@doric-blockchain/doric-core/actions/wallet'
import { SEED_DETAILS } from 'store/application/types'
import { useApplicationState } from 'store/application/state'
import { AutoColumn, ColumnCenter } from 'components/Column'
import PageTitle from 'components/Titles/Page'
import ExclamationIcon from 'components/SVG/ExclamationIcon'
import { ButtonLight } from 'components/Button'
import CheckPassword from 'components/CheckPassword'
import { ShadowCard } from 'components/Card'
import Row from 'components/Row'
import { CopyTransitionText, ClickSaveAsJson } from 'components/CopyText'
import { SimpleGrid } from 'pages/Template/styles'
import { SeedBox } from '../Seed/styles'
import { TYPE } from '../../theme'

const WrapperBackUp = styled.div`
  width: ${({ width }) => width ?? '100%'};
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1.25rem;
`

const SeedCardCopy = styled(ColumnCenter)`
  margin-top: 20px;
`

const ButtonCard = styled(Row, ColumnCenter)`
  display: flex;
  margin-top: 15px;
  width: 60%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    width: 100%;
  `};
`

const HandlerSeedBox = styled(SeedBox)`
  filter: ${({ showSeed }) => (showSeed ? 'none' : 'blur(1px)')};
  user-select: ${({ showSeed }) => (showSeed ? 'auto' : 'none')};
  cursor: ${({ showSeed }) => (showSeed ? 'auto' : 'pointer')};
  font-size: 11px;
  margin: 0px;
  margin-bottom: 15px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};

  p {
    overflow-x: auto;
  }
`

const CardSection = styled(AutoColumn)`
  grid-template-columns: 1fr;
  gap: 8px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: auto;
    grid-template-rows: auto;
  `};
`

const Backup = ({ get_seed_and_pk, get_key_store }) => {
  const { t } = useTranslation()
  const { openPopup, closePopup } = useApplicationState()
  const [seed, setSeed] = useState(null)
  const [privateKey, setPrivateKey] = useState(null)
  const [copied, setCopied] = useState(false)
  const [jsonSaved, setJsonSaved] = useState(false)

  function handlerOpenModal() {
    if (!seed)
      openPopup(SEED_DETAILS, () => (
        <CheckPassword callback={checkSeed} confirmText={t(`Unlock wallet`)} />
      ))
  }

  function handlerGetKeyStore() {
    get_key_store({
      callback: ks =>
        fileDownload(ks, `${process.env.REACT_APP_COIN_NAME}-seed-wallet.json`),
    })
    setJsonSaved(true)

    setTimeout(() => {
      setJsonSaved(false)
    }, 1000)
  }

  function checkSeed({ password, onError, startLoading, stopLoading }) {
    startLoading()

    get_seed_and_pk({
      password,
      success: ({ seed: newSeed, privateKey: newPrivate }) => {
        setSeed(newSeed)
        setPrivateKey(newPrivate)
        closePopup(SEED_DETAILS)
      },
      error: () => {
        onError(true)
        stopLoading()
      },
    })

    return true
  }

  function handleCopied() {
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 1000)

    copy(seed)
  }

  return (
    <SimpleGrid>
      <CardSection>
        <ShadowCard>
          <WrapperBackUp>
            <PageTitle>{t(`Export your wallet`)}</PageTitle>

            <TYPE.body>
              {t(`The mnemonic phrase is actually a
                representation of a binary seed (Master Seed) each keyword represents a hexadecimal code.`)}
            </TYPE.body>

            <SeedCardCopy>
              <TYPE.label>Your 12-word phrase</TYPE.label>
              <HandlerSeedBox showSeed={seed} onClick={handlerOpenModal}>
                <p>{seed ?? `**** **** **** **** ****`}</p>
              </HandlerSeedBox>
            </SeedCardCopy>

            <SeedCardCopy>
              <TYPE.label>Wallet private key</TYPE.label>
              <HandlerSeedBox showSeed={seed} onClick={handlerOpenModal}>
                <p>{privateKey ?? `**** **** **** **** ****`}</p>
              </HandlerSeedBox>
            </SeedCardCopy>

            <TYPE.label pt="2">
              <ExclamationIcon />
              <span style={{ marginLeft: '4px' }}>
                {`${t(`Remember`)}: `}
                {t(`don't share your secrets with anyone.`)}
              </span>
            </TYPE.label>

            <ButtonCard>
              {seed && (
                <ButtonLight margin="5px" width="100%" onClick={handleCopied}>
                  <CopyTransitionText
                    isClicked={copied}
                    textBeforeClick={t(`Copy mnemonic`)}
                    textAfterClick={t(`copied`)}
                  />
                </ButtonLight>
              )}

              {!seed && (
                <ButtonLight
                  margin="5px"
                  width="100%"
                  onClick={handlerOpenModal}
                >
                  <CopyTransitionText
                    isClicked={copied}
                    textBeforeClick={t(`Unlock wallet`)}
                    textAfterClick={t(`Type your password`)}
                  />
                </ButtonLight>
              )}

              <ButtonLight
                onClick={handlerGetKeyStore}
                margin="5px"
                width="100%"
                variant="success"
              >
                <ClickSaveAsJson
                  isClicked={jsonSaved}
                  textBeforeClick={t(`export JSON (encrypted)`)}
                  textAfterClick={t(`downloading`)}
                />
              </ButtonLight>
            </ButtonCard>
          </WrapperBackUp>
        </ShadowCard>
      </CardSection>
    </SimpleGrid>
  )
}

const state = ({ password }) => ({ password })
export default connect(state, { get_seed_and_pk, get_key_store })(Backup)
