import styled from 'styled-components'

export const ImageContainer = styled.div`
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  .text1 {
    color: ${({ theme }) => theme.text1};
    font-size: 24px;
  }
  .text2 {
    color: ${({ theme }) => theme.text2};
    font-size: 16px;
  }
  .next,
  .previous {
    width: 250px;
    height: 36px;
    border: none;
    background: ${({ theme }) => theme.primary1};
    border-radius: 25px;
    color: white;
    font-size: 16px;
    margin-top: 10px;
    outline: none;
    cursor: pointer;
  }
  .previous {
    sbackground: none;
  }
  .container {
    padding: 25px;
  }
`

export const FlexGrid = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
