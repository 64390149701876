import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { ExternalLink as LinkIcon, LogOut } from 'react-feather'
import { logout } from '@doric-blockchain/doric-core/actions/wallet'
import { getExplorerURL } from 'constants/explorer'
import { useNetworkState } from 'store/network/state'
import { device } from 'utils/devices'

import CopyText from '../CopyText'
import { ButtonSecondary } from '../Button'
import { ExternalLink } from '../../theme'
import { parseENSAddress } from 'utils/parseENSAddress'
import { useApplicationState } from 'store/application/state'
import { ACCOUNT_DETAILS } from 'store/application/types'

const WalletAction = styled(ButtonSecondary)`
  width: fit-content;
  font-weight: 400;
  font-size: 0.825rem;
  padding: 10px;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const Content = styled.div`
  width: 100%;
  padding: 40px 40px 10px 10px;

  @media ${device.laptop} {
    padding: 40px 40px 10px 10px;
  }
`

const HeaderRow = styled.div`
  margin: 0 0 50px;
`

const HeaderRowTitle = styled.h2`
  font-size: 35px;
  font-weight: 600;
  margin: 0 0 10px;
`

const HeaderRowSubtitle = styled.p`
  font-size: 18px;
  margin: 0;
`

const IconButtonBox = styled.span`
  margin: 0 4px 0 0;
`

const AccountInfoBox = styled.div``

const AccountControl = styled.div`
  align-items: center;
  display: flex;
  margin: 60px 0 0;
  min-width: 0;
  width: 100%;
  font-size: 18px;

  & > * {
    border: 1px solid ${({ theme }) => theme.gray1};
  }

  & > *:first-child,
  & > *:nth-child(2) {
    border-radius: 12px;
    margin-right: 15px;
    padding: 10px;
  }

  & a,
  & button {
    align-items: center;
    display: flex;
  }

  a:hover {
    text-decoration: underline;
  }

  p {
    min-width: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const AccountInfoContainer = styled.div`
  align-items: center;
  display: flex;

  & > *:first-child {
    margin-right: 40px;
  }
`

const AccountInfoLabel = styled.h3`
  font-size: 25px;
  font-weight: 600;
  margin: 0 0 5px;
`

const AccountInfoValue = styled.p`
  font-size: 18px;
  margin: 0;
`

const AddressLink = styled(ExternalLink)`
  color: ${({ theme }) => theme.text3};

  display: block;
  font-size: 0.825rem;
  :hover {
    color: ${({ theme }) => theme.text2};
  }
`

function AccountDetails({ client, logout, history }) {
  const { selectedNetwork } = useNetworkState()
  const { closePopup } = useApplicationState()
  const { balance, address } = client

  const urlExplorer = getExplorerURL(selectedNetwork)

  return (
    <>
      <Content>
        <HeaderRow>
          <HeaderRowTitle>Account details</HeaderRowTitle>
          <HeaderRowSubtitle>
            Check you current account information.
          </HeaderRowSubtitle>
        </HeaderRow>
        <AccountInfoContainer>
          <AccountInfoBox id="web3-account-identifier-row">
            <AccountInfoLabel>Address</AccountInfoLabel>
            <AccountInfoValue>{parseENSAddress(address)}</AccountInfoValue>
          </AccountInfoBox>
          <AccountInfoBox>
            <AccountInfoLabel>Balance</AccountInfoLabel>
            <AccountInfoValue>
              {balance} {process.env.REACT_APP_COIN_SYMBOL}
            </AccountInfoValue>
          </AccountInfoBox>
        </AccountInfoContainer>

        <AccountControl>
          <CopyText toCopy={address}>
            <span style={{ marginLeft: '4px' }}>
              <Trans>Copy Address</Trans>
            </span>
          </CopyText>

          <AddressLink href={urlExplorer}>
            <IconButtonBox>
              <LinkIcon size={16} />
            </IconButtonBox>

            <span style={{ marginLeft: '4px' }}>
              <Trans>View on Explorer</Trans>
            </span>
          </AddressLink>

          <WalletAction
            style={{
              fontSize: '.825rem',
              fontWeight: 500,
            }}
            onClick={() =>
              logout({
                success: () => {
                  history.push(`/login`)
                  closePopup(ACCOUNT_DETAILS)
                },
              })
            }
            data-cy="btn-logout"
          >
            <IconButtonBox>
              <LogOut size={16} />
            </IconButtonBox>
            <Trans>Disconnect</Trans>
          </WalletAction>
        </AccountControl>
      </Content>
    </>
  )
}

const state = ({ client }) => ({ client })
export default connect(state, { logout })(withRouter(AccountDetails))
