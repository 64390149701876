import { connect } from 'react-redux'
import { init } from '@doric-blockchain/doric-core/actions/main'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useNetworkState } from 'store/network/state'

const InitController = ({ init }) => {
  const { selectedNetwork } = useNetworkState()
  const history = useHistory()

  useEffect(() => {
    init(
      {
        handleLogin: () => {
          history.push('/login')
        },
        handleDashboard: () => {
          history.push('/')
        },
        handleOnBoarding: () => {
          history.push('/welcome')
        },
      },
      selectedNetwork,
    )
  }, [init, history, selectedNetwork])

  return null
}

export default connect(null, { init })(InitController)
