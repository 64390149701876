import React from 'react'
import { useTranslation } from 'react-i18next'
import { SlideControls } from 'components'
import styled from 'styled-components'
import ImageSlide2 from './blockchain-slide.png'
import SlideContainer from '../SlideContainer'
import { ButtonGray, ButtonLight } from 'components/Button'
import { ColumnCenter } from 'components/Column'
import { TYPE } from 'theme'

const CardImage = styled.div`
  max-width: 400px;
`

const ButtonCard = styled(ColumnCenter)`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  width: 60%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};
`

const Slide2 = ({ history }) => {
  const { t } = useTranslation()

  return (
    <SlideContainer>
      <CardImage>
        <img src={ImageSlide2} width="100%" alt={``} />
      </CardImage>

      <TYPE.largeHeader>{t(`ERC20 Standard`)}</TYPE.largeHeader>
      <TYPE.body>
        {t(`Your address is compatible with all ERC20 wallets`)}
      </TYPE.body>
      <SlideControls />

      <ButtonCard>
        <ButtonGray
          margin="5px"
          width="100%"
          onClick={() => history.push(`/slide/1`)}
        >
          {t(`Go back`)}
        </ButtonGray>

        <ButtonLight
          margin="5px"
          width="100%"
          onClick={() => history.push(`/slide/3`)}
          data-cy="btn-continue-slide-2"
        >
          {t(`Next`)}
        </ButtonLight>
      </ButtonCard>
    </SlideContainer>
  )
}

export default Slide2
