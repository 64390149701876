import React from 'react'
import styled from 'styled-components'
import { useLocalState } from 'store/local/state'

const Image = styled.img`
  filter: ${({ isDark }) =>
    isDark
      ? 'invert(100%) sepia(100%) saturate(0%) hue-rotate(195deg) brightness(99%) contrast(105%)'
      : ''};
`

const DoricLogo = () => {
  const { isDarkMode } = useLocalState()

  return <Image src="doric_logo.svg" isDark={isDarkMode} />
}

export default DoricLogo
