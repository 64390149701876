import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { HideSmall, TYPE } from 'theme'
import Card, { ShadowCard } from 'components/Card'
import { SimpleGrid } from 'pages/Template/styles'
import RestoreWallet from './RestoreWallet'
import CreateWallet from './CreateWallet'

const TextSection = styled(Card)`
  display: flex;
  justify-content: center;
`

const SignupPage = () => {
  const { t } = useTranslation()

  return (
    <SimpleGrid>
      <HideSmall>
        <TextSection>
          <TYPE.largeHeader>
            {t(`Get Started by Signing Up Now`)}
          </TYPE.largeHeader>
        </TextSection>
      </HideSmall>

      <SimpleGrid template="3fr 2fr">
        <ShadowCard>
          <CreateWallet />
        </ShadowCard>

        <ShadowCard>
          <RestoreWallet />
        </ShadowCard>
      </SimpleGrid>
    </SimpleGrid>
  )
}

export default SignupPage
