import React from 'react'
import { useTranslation } from 'react-i18next'
import { Key, DownloadCloud, Feather } from 'react-feather'
import { connect } from 'react-redux'
import { TYPE } from 'theme'
import { useHistory } from 'react-router-dom'
import {
  create_wallet,
  restore_wallet_by_keystore,
} from '@doric-blockchain/doric-core/actions/wallet'
import { clientSyncRefresh } from '@doric-blockchain/doric-core/actions/main'
import { useApplicationState } from 'store/application/state'
import { SET_SEED_WORDS, RESTORE_BY_KEY_STORE } from 'store/application/types'
import { useNetworkState } from 'store/network/state'
import { AutoRow, RowBetween } from 'components/Row'
import Card from 'components/Card'
import { Box } from 'rebass/styled-components'
import { ButtonPrimary } from 'components/Button'
import CheckSeedWords from 'components/CheckSeedWords'
import CheckFromKeyStore from 'components/CheckFromKeyStore'

const RestoreWallet = ({
  create_wallet,
  clientSyncRefresh,
  restore_wallet_by_keystore,
}) => {
  const { t } = useTranslation()
  const { selectedNetwork } = useNetworkState()
  const { openPopup, closePopup } = useApplicationState()
  const history = useHistory()

  function refreshWallet({ address }) {
    history.push(`/`)
    clientSyncRefresh({ address }, selectedNetwork)
  }

  function checkSeed({ password, words, onError, startLoading, stopLoading }) {
    startLoading()

    create_wallet({
      password,
      seed: words,
      onSuccess: params => {
        refreshWallet(params)
        closePopup(SET_SEED_WORDS)
      },
      onFail: error => {
        onError(error)
        stopLoading()

        setTimeout(() => {
          onError(false)
        }, 4000)
      },
    })
  }

  function checkKeyStore({
    password,
    keyStore,
    onError,
    startLoading,
    stopLoading,
  }) {
    startLoading()

    restore_wallet_by_keystore(
      {
        password,
        keyStore,
        onSuccess: () => {
          closePopup(RESTORE_BY_KEY_STORE)
          history.push('/')
        },
        onFail: error => {
          onError(error)
          stopLoading()

          setTimeout(() => {
            onError(false)
          }, 4000)
        },
      },
      selectedNetwork,
    )
  }

  function openModalRestoreBySeed() {
    openPopup(SET_SEED_WORDS, () => (
      <CheckSeedWords callback={checkSeed} confirmText={t(`Restore walllet`)} />
    ))
  }

  function openModalRestoreByKeyStore() {
    openPopup(RESTORE_BY_KEY_STORE, () => (
      <CheckFromKeyStore
        callback={checkKeyStore}
        confirmText={t(`Restore wallet`)}
      />
    ))
  }

  return (
    <Card>
      <Box mb="3">
        <AutoRow>
          <Key width="22px" />
          <TYPE.mediumHeader ml="10px">
            {t(`Restore your wallet`)}
          </TYPE.mediumHeader>
        </AutoRow>
      </Box>

      <Box mb="3">
        <AutoRow>
          <TYPE.subHeader>
            {t(`Click in one of the below options to access your wallet`)}
          </TYPE.subHeader>
        </AutoRow>
      </Box>

      <RowBetween>
        <ButtonPrimary
          padding="2"
          mr="1"
          mb="2"
          onClick={openModalRestoreBySeed}
          data-cy="btn-restore-by-seed"
        >
          <Feather width="16px" m="5" />
          <TYPE.white ml="10px">{t(`Seed`)}</TYPE.white>
        </ButtonPrimary>

        <ButtonPrimary
          padding="2"
          mb="2"
          onClick={openModalRestoreByKeyStore}
          data-cy="btn-restore-by-json"
        >
          <DownloadCloud width="16px" m="5" />
          <TYPE.white ml="10px">{t(`Load file`)}</TYPE.white>
        </ButtonPrimary>
      </RowBetween>
    </Card>
  )
}

const state = ({ password, confirm_password }) => ({
  password,
  confirm_password,
})

export default connect(state, {
  create_wallet,
  clientSyncRefresh,
  restore_wallet_by_keystore,
})(RestoreWallet)
