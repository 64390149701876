import React from 'react'
import { FlexGrid, ImageContainer } from './styles'
import { ShadowCard } from 'components/Card'

const SlideContainer = ({ children }) => {
  return (
    <ImageContainer>
      <ShadowCard>
        <FlexGrid className="container">{children}</FlexGrid>
      </ShadowCard>
    </ImageContainer>
  )
}

export default SlideContainer
