import { useState, useEffect } from 'react'
import axios from 'axios'

const usePriceSimulation = () => {
  const [prices, setPrices] = useState([])

  function calcByAmount(amount) {
    const PRICE_DRC = parseFloat(amount * 4.8).toFixed(2)

    return prices.map(({ symbol, price }) => {
      switch (symbol) {
        case 'BRL':
          return {
            symbol,
            price: '4.80',
            total: PRICE_DRC,
          }
        case 'USD':
          return {
            symbol,
            price,
            total: parseFloat(PRICE_DRC / price),
          }
        case 'EUR':
          return {
            symbol,
            price: parseFloat(4.8 / price).toFixed(2),
            total: parseFloat(PRICE_DRC / price),
          }
        default:
          return {
            symbol,
            price: parseFloat(4.8 / price).toFixed(2),
            total: parseFloat(amount * price).toFixed(2),
          }
      }
    })
  }

  useEffect(() => {
    async function get() {
      try {
        const { data: response } = await axios.get(
          'https://economia.awesomeapi.com.br/last/BRL-USD,USD-BRL,USD-EUR',
        )

        setPrices(
          Object.keys(response).map(key => {
            const { code, bid } = response[key]
            return { symbol: code, price: bid }
          }),
        )
      } catch (error) {
        console.error(
          `We found an error when search for balance of coins > ${error}`,
        )
      }
    }

    get()
  }, [])

  return { prices, calcByAmount }
}

export default usePriceSimulation
