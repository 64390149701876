import React from 'react'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { SlideControls } from 'components'
import ImageSlide1 from './security-slide.png'
import SlideContainer from '../SlideContainer'
import { ButtonGray, ButtonLight } from 'components/Button'
import { ColumnCenter } from 'components/Column'
import { TYPE } from 'theme'

const CardImage = styled.div`
  max-width: 400px;
`

const ButtonCard = styled(ColumnCenter)`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  width: 60%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};
`

const Slide1 = ({ history }) => {
  const { t } = useTranslation()

  return (
    <SlideContainer>
      <CardImage>
        <img src={ImageSlide1} width="100%" alt={``} />
      </CardImage>

      <TYPE.largeHeader>{t(`Private and Safe`)}</TYPE.largeHeader>
      <TYPE.body>{t(`The Private Key never left your device`)}</TYPE.body>
      <SlideControls />

      <ButtonCard>
        <ButtonGray
          margin="5px"
          width="100%"
          onClick={() => history.push(`/welcome`)}
        >
          {t(`Go back`)}
        </ButtonGray>

        <ButtonLight
          margin="5px"
          width="100%"
          onClick={() => history.push(`/slide/2`)}
          data-cy="btn-continue-slide-1"
        >
          {t(`Next`)}
        </ButtonLight>
      </ButtonCard>
    </SlideContainer>
  )
}

export default withRouter(Slide1)
