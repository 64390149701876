import React from 'react'
import { SimpleGrid } from 'pages/Template/styles'
import WalletCard from './WalletCard'

const Dashboard = () => {
  return (
    <SimpleGrid>
      <WalletCard />
    </SimpleGrid>
  )
}

export default Dashboard
