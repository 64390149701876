import 'inter-ui'
import React from 'react'
import { Provider } from 'react-redux'
import { store as StoreCore } from '@doric-blockchain/doric-core'
import './i18n'
import App from './pages/App'
import ThemeProvider, { FixedGlobalStyle, ThemedGlobalStyle } from './theme'
import { useLocalState } from 'store/local/state'

const Root = () => {
  const { isDarkMode } = useLocalState()

  return (
    <>
      <FixedGlobalStyle />
      <Provider store={StoreCore}>
        <ThemeProvider darkMode={isDarkMode}>
          <ThemedGlobalStyle />
          <App />
        </ThemeProvider>
      </Provider>
    </>
  )
}

export default Root
