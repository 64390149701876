import styled from 'styled-components'

export const Title = styled.div`
  height: 100%;
  border-bottom: 1px solid rgb(218, 218, 218);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  span {
    font-size: 16px;
    color: #9c9c9c;
    padding-right: 40px;
  }
`

export const Footer = styled.div`
  height: 100%;
  border-top: 1px solid #dadada;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: blue;
  }
`

export const Box = styled.div`
  background-color: #efeff1;
  width: 713px;
  height: 484px;
  margin-top: 20px;
  border-radius: 7px;
  position: relative;
  display: grid;
  grid-template-rows: 87px 2fr 87px;

  p {
    color: rgb(156, 156, 156);
  }
  .text {
    max-width: 364px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 40px;
    text-align: center;
  }
  p {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  button {
    width: 594px;
    height: 59px;
    border: none;
    background: #327bc8;
    border-radius: 25px;
    color: white;
    font-size: 16px;
    outline: none;
    cursor: pointer;
    margin: 10px;
  }
  footer {
    position: absolute !important;
    bottom: 0px !important;
    width: 100% !important;
    display: flex;
    text-align: center;
    border-top: 1px solid #dadada;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: blue;
  }
`

export const Loader = styled.div`
  background-color: #d8d8d8;
  width: 594px;
  height: 53px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  border-radius: 30px;
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
  div {
    margin-left: 10px;
    height: 35px;
    border-radius: 20px;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #11162e;
  }
`
