import React, { useEffect, useState } from 'react'
import { SeedSelector, SeedBox } from './styles'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { is_valid_input_seed } from '@doric-blockchain/doric-core/helpers/wallet'
import Card, { ShadowCard } from 'components/Card'
import { TYPE } from 'theme'
import styled from 'styled-components'
import { ButtonGray, ButtonLight } from 'components/Button'
import { SimpleGrid } from 'pages/Template/styles'
import { AutoRow } from 'components/Row'
import { shuffleArray } from 'utils/array/sort'
import { ColumnCenter } from 'components/Column'

const ButtonCard = styled(ColumnCenter)`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  width: 60%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};
`

const CardSection = styled(Card)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`

const InformSeed = ({ history, seed }) => {
  const { t } = useTranslation()

  const [chosenWords, setChosenWords] = useState([])
  const [sortedWords, setSortedWords] = useState([])

  const { string: WALLET_SEED } = seed

  useEffect(() => {
    if (seed) {
      const words = WALLET_SEED.split(' ')
      setSortedWords(shuffleArray(words))
    }
  }, [seed, WALLET_SEED])

  function chooseSeed(word) {
    setChosenWords([...chosenWords, word])
  }

  function removeSeed(word) {
    const newChosen = chosenWords.filter(filterWord => filterWord !== word)
    setChosenWords(newChosen)
  }

  const getRemaining = (sorted, chosen) => {
    const result = []

    for (let a = 0; a < sorted.length; a++) {
      let found = false

      for (let b = 0; b < chosen.length; b++)
        if (sorted[a] === chosen[b]) found = true
      if (!found) result.push(sorted[a])
    }

    return result
  }

  const remaining = getRemaining(sortedWords, chosenWords)

  return (
    <SimpleGrid>
      <ShadowCard>
        <CardSection>
          <AutoRow>
            <TYPE.largeHeader ml="10px">{t('Seed')}</TYPE.largeHeader>
          </AutoRow>
          <center>
            <TYPE.body maxWidth="314" mb="20px">
              {t(
                `Tap the words to put them next to each other in the correct order`,
              )}
            </TYPE.body>
            <SeedSelector>
              {remaining?.map((word, key) => (
                <span
                  key={key}
                  onClick={() => chooseSeed(word)}
                  className={`word`}
                  data-cy={`seed-word-${word}`}
                >
                  {word}
                </span>
              ))}
            </SeedSelector>
          </center>
          <SeedBox>
            {chosenWords?.map(cs => (
              <span className={`word`} key={cs} onClick={() => removeSeed(cs)}>
                {cs}
              </span>
            ))}
          </SeedBox>

          <ButtonCard>
            <ButtonGray
              margin="5px"
              width="100%"
              onClick={() => history.go(-1)}
              style={{ cursor: `pointer` }}
            >
              {t(`Go back`)}
            </ButtonGray>

            <ButtonLight
              mr="1"
              disabled={!is_valid_input_seed(WALLET_SEED, chosenWords)}
              onClick={() => history.push(`/signup`)}
              data-cy="btn-create-password"
            >
              <TYPE.white ml="10px">{t(`Continue`)}</TYPE.white>
            </ButtonLight>
          </ButtonCard>
        </CardSection>
      </ShadowCard>
    </SimpleGrid>
  )
}

const state = ({ shuffle, chosen_seeds, seed }) => ({
  shuffle,
  chosen_seeds,
  seed,
})
export default connect(state)(withRouter(InformSeed))
