import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SlideControls } from 'components'
import styled from 'styled-components'
import ImageSlide4 from './terms-slide.png'
import { withRouter } from 'react-router-dom'
import SlideContainer from '../SlideContainer'
import { ButtonGray, ButtonLight } from 'components/Button'
import { ColumnCenter } from 'components/Column'
import { TYPE } from 'theme'

const CardImage = styled.div`
  max-width: 400px;
`

const ButtonCard = styled(ColumnCenter)`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  width: 60%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};
`

const Box = styled.div`
  width: 400px;
`

const Slide4 = ({ history }) => {
  const { t } = useTranslation()

  const [chek1, set_check1] = useState(false)
  const [chek2, set_check2] = useState(false)

  return (
    <SlideContainer style={{ maxWidth: '500px' }}>
      <CardImage>
        <img src={ImageSlide4} width="100%" alt={``} />
      </CardImage>

      <Box>
        <TYPE.largeHeader>
          {t(`We are almost there! Let revise it`)}
        </TYPE.largeHeader>
        <h4
          style={{ cursor: 'pointer' }}
          className={`text2`}
          onClick={() => set_check1(!chek1)}
        >
          <input
            type={`checkbox`}
            checked={chek1}
            readOnly
            style={{ marginRight: 10 }}
            data-cy="checkbox1-slide-4"
          />
          <span>
            {t(
              `I understand that all my funds are kept safely in this device, and not on ${process.env.REACT_APP_COIN_NAME}'s servers.`,
            )}
          </span>
        </h4>
        <h4
          style={{ cursor: 'pointer' }}
          className={`text2`}
          onClick={() => set_check2(!chek2)}
        >
          <input
            type={`checkbox`}
            checked={chek2}
            readOnly
            style={{ marginRight: 10 }}
            data-cy="checkbox2-slide-4"
          />
          <span>
            {t(
              `I understand that if this application was deleted, my ${process.env.REACT_APP_COIN_NAME} Blockchain can only be recovered with the (json) backup file or my seed phrase.`,
            )}
          </span>
        </h4>
      </Box>
      <SlideControls />
      <ButtonCard>
        <ButtonGray
          margin="5px"
          width="100%"
          onClick={() => history.push(`/slide/3`)}
        >
          {t(`Go back`)}
        </ButtonGray>

        <ButtonLight
          margin="5px"
          width="100%"
          disabled={!(chek1 && chek2)}
          onClick={() => history.push(`/seed`)}
          data-cy="btn-continue-slide-4"
        >
          {t(`Confirm and Finish`)}
        </ButtonLight>
      </ButtonCard>
    </SlideContainer>
  )
}

export default withRouter(Slide4)
