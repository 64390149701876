import React from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import Error403 from 'components/Error403'

const ProtectedRoute = ({
  path,
  component,
  authRequired,
  authentication,
  exact,
}) => {
  const { logged } = authentication || {}

  const show = (authRequired && logged) || !authRequired
  const render = show ? component : Error403

  return <Route path={path} component={render} exact={exact} />
}

const state = ({ authentication }) => ({ authentication })
export default connect(state)(ProtectedRoute)
