import React from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { CreditCard } from 'react-feather'
import { TYPE } from 'theme'
import { Box } from 'rebass/styled-components'

import { AutoRow, RowBetween } from 'components/Row'
import Card from 'components/Card'
import { ButtonPrimary } from 'components/Button'

const CreateWallet = ({ history }) => {
  const { t } = useTranslation()

  return (
    <Card>
      <Box mb="3">
        <AutoRow>
          <CreditCard width="22px" />
          <TYPE.largeHeader ml="10px">{t('Create a new')}</TYPE.largeHeader>
        </AutoRow>
      </Box>

      <Box mb="3">
        <AutoRow>
          <TYPE.blue>
            {t('Privacy in sending, receiving and storing values')}
          </TYPE.blue>
        </AutoRow>
      </Box>

      <Box mb="3">
        <AutoRow>
          <TYPE.label>{t('The wallet has been fully')}</TYPE.label>
          <div>&nbsp;</div>
          <TYPE.darkGray>{t('developed in our own blockchain')}</TYPE.darkGray>
        </AutoRow>

        <AutoRow>
          <TYPE.label>{t('Possible to send, receive ')}</TYPE.label>
          <div>&nbsp;</div>
          <TYPE.darkGray>{t('and store cryptoassets safely')}</TYPE.darkGray>
        </AutoRow>
      </Box>

      <RowBetween>
        <ButtonPrimary
          padding="2"
          onClick={() => history.push(`/slide/1`)}
          data-cy="btn-create-wallet"
        >
          <TYPE.white ml="10px">{t(`Create wallet`)}</TYPE.white>
        </ButtonPrimary>
      </RowBetween>
    </Card>
  )
}

export default withRouter(CreateWallet)
