import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, TYPE } from 'theme'

function CustomErrorBoundary() {
  const history = useHistory()

  const reload = () => {
    if (window.location.pathname === `/`) {
      window.location.reload()
    } else history.push('/')
  }

  return (
    <div
      style={{
        textAlign: 'center',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
      }}
    >
      <div>
        <TYPE.largeHeader style={{ fontSize: '3rem' }}>Oops</TYPE.largeHeader>
        <TYPE.mediumHeader style={{ fontSize: '2rem' }}>
          Something went wrong! 😧
        </TYPE.mediumHeader>
      </div>
      <Button
        style={{
          width: '200px',
        }}
        onClick={reload}
      >
        Back to home
      </Button>
    </div>
  )
}

export default CustomErrorBoundary
