import React from 'react'
import { Container } from '../../components'
import { Box, Footer, Loader, Title } from './styles'
import { useTranslation } from 'react-i18next'

const NewWallet = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <Box>
        <Title>
          <span>{t(`New Wallet`)}</span>
        </Title>
        <div>
          <div>
            <p className={`text`}>
              {t(`Please keep moving the mouse till it get 100%`)}
            </p>
            <Loader>
              <div>20%</div>
            </Loader>
            <p>
              <button>{t(`Continue`)}</button>
            </p>
          </div>
        </div>
        <Footer>
          <span>{t(`Go back`)}</span>
        </Footer>
      </Box>
    </Container>
  )
}

export default NewWallet
